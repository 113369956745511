import React, { useContext } from "react";
import { Form, Input, Select, Button, Typography, Image } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
import CountriesCode from "./CountriesCode.json";
import logo from "../../assets/images/ivcardLogo.png";
import { useHistory } from "react-router-dom";
import Wave from "./Wave";
import { banfsag, light } from "../../styles/colors";
import { postLogin } from "../../API/fetch";
import { DataContext } from "../../context";

const { Text, Title } = Typography;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { setUser } = useContext(DataContext);
  const onFinish = async (values) => {
    const data = {
      credentials: {
        ...values,
      },
    };
    try {
      const res = await postLogin(data);

      setUser(res.data.user);
      history.push(`/info/${res.data.user.slug}`);
    } catch (error) {
      console.log(error.response.data);
      if (
        error.response?.data?.message ===
        "The provided username or password is incorrect"
      ) {
        alert("The provided username or password is incorrect");
      } else {
        alert("Something went wrong, please try again");
      }
    }
  };

  const prefixSelector = (
    <Form.Item name='prefixCellPhone' noStyle>
      <Select
        style={{
          width: 130,
        }}
      >
        {CountriesCode.map((c) => {
          return (
            <Option key={c.code} value={c.dial_code}>
              {c.dial_code} - {c.code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <div
      className='my-font'
      style={{ minHeight: "100vh", backgroundColor: "#fafafa" }}
    >
      <div>
        <Wave />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            position: "absolute",
            top: 5,
            left: 0,
            right: 0,
          }}
        >
          <Image
            preview={false}
            onClick={() => history.push("/")}
            src={logo}
            style={{ maxWidth: 100, resizeMode: "contain" }}
          />
          <Title level={4} align='center' style={{ color: banfsag }}>
          iVcard بطاقة الأعمال الذكية
          </Title>
        </div>
      </div>
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
        initialValues={{
          prefixCellPhone: "+966",
        }}
        scrollToFirstError
      >
        <Form.Item
          name='cellPhone'
          label='رقم الجوال'
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name='password'
          label='كلمة المرور'
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            shape='round'
            style={{ backgroundColor: banfsag, color: light }}
            htmlType='submit'
          >
            تسجيل الدخول
          </Button>
        </Form.Item>
        <Text>ليس لديك حساب ؟</Text>
        <Button
          type='link'
          style={{ color: banfsag }}
          onClick={() => history.push("/register")}
        >
          إنشاء حساب جديد
        </Button>
      </Form>
    </div>
  );
};

export default Login;
