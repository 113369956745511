import {
  Card,
  Avatar,
  Space,
  Typography,
  Row,
  Divider,
  Layout,
  Form,
  Input,
  Button,
} from "antd";

import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { DataContext } from "../context";
import LOGO from "../assets/images/box-logo.png";
import { cardUrl, photoUrl } from "../API/config";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../API/fetch";

const { Meta } = Card;
const { Text,  Paragraph } = Typography;
const Profile = () => {
  const history = useHistory();
  const { user, setUser } = useContext(DataContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      newPassword: values.newPassword,
    };
    try {
      const res = await resetPassword(data, user.token);
      setLoading(false);
      setUser(res.data.user);
      history.push(`/info/${res.data.user.slug}`);
    } catch (error) {
      setLoading(false);
      console.log(error.response.data);
      if (error.response?.data?.message === "password is incorrect") {
        form.setFields([
          {
            name: "currentPassword",
            errors: [
              {
                message: "password is incorrect",
              },
            ],
          },
        ]);
      }
    }
  };

  if (!user) {
    return <Redirect to='/register' />;
  }
  return (
    <Layout
      style={{
        minHeight: "100vh",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#f1f1f1",
      }}
    >
      <Card
        style={{
          width: window.innerWidth > 500 ? 400 : "85vw",
          marginTop: 30,
          marginBottom: "auto",
          backgroundColor: "#ffffff",
          borderRadius: 20,
          overflow: "hidden",
        }}
        cover={
          user?.bg ? (
            <img alt='example' src={`${photoUrl}/${user.bg}`} />
          ) : (
            <img alt='example' src={LOGO} />
          )
        }
      >
        <Meta
          avatar={<Avatar src={`${photoUrl}/${user.photo}`} />}
          title={`${user?.firstName} ${
            user?.middleName ? user?.middleName : ""
          } ${user?.lastName ? user?.lastName : ""}`}
        />
        <Row
          flex={1}
          justify='start'
          align='middle'
          style={{ marginTop: 30, marginBottom: 10 }}
        >
          <Text level={5}>الموقع الالكتروني</Text>
          <Paragraph
            copyable
          >{`https://ivcard.link/info/${user?.slug}`}</Paragraph>
          <Text level={5}>QR CODE</Text>
          <Paragraph copyable>{`${cardUrl}/${user?.slug}.vcf`}</Paragraph>
          <Text level={5}>CVF ملف</Text>
          <Paragraph copyable>{`${cardUrl}/${user?.slug}.vcf`}</Paragraph>
        </Row>
        <Divider />
        <Form
          form={form}
          name='register'
          onFinish={onFinish}
          style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
          initialValues={{
            newPassword: "",
            confirmPassword: "",
          }}
          scrollToFirstError
        >
          <Form.Item
            name='newPassword'
            label='كلمة المرور الجديدة'
            rules={[
              {
                required: true,
                message: "يرجى إدخال كلمة المرور الجديدة",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name='confirmPassword'
            label='تأكيد كلمة المرور'
            rules={[
              {
                required: true,
                message: "يرجى تأكيد كلمة المرور",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("يجب أن تكون كلمة المرور متطابقة");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
                disabled={loading}
                style={{
                  width: "100%",
                  marginBottom: 10,
                  borderRadius: 10,
                  backgroundColor: "#212121",
                  borderColor: "#212121",
                }}
              >
                تغيير كلمة المرور
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  );
};

export default Profile;
