import React, { useContext } from "react";
import { Form, Input, Select, Checkbox, Button, Typography, Image } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
import CountriesCode from "./CountriesCode.json";
import logo from "../../assets/images/ivcardLogo.png";
import { useHistory } from "react-router-dom";
import Wave from "./Wave";
import { banfsag, light } from "../../styles/colors";
import { postSignup } from "../../API/fetch";
import { DataContext } from "../../context";
const { Text, Title } = Typography;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const Register = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { setUser } = useContext(DataContext);

  const onFinish = async (values) => {
    const data = {
      credentials: {
        ...values,
      },
    };
    delete data.credentials.agreement;

    try {
      const res = await postSignup(data);

      setUser(res.data.user);
      history.push(`/info/${res.data.user.slug}`);
    } catch (error) {
      if (
        error.response?.data?.message === "This phone is already registered"
      ) {
        alert("This phone is already registered, please Log in");
      } else {
        alert("Something went wrong, please try again");
      }
    }
  };

  const prefixSelector = (
    <Form.Item name='prefixCellPhone' noStyle>
      <Select
        style={{
          width: 130,
        }}
      >
        {CountriesCode.map((c) => {
          return (
            <Option key={c.code} value={c.dial_code}>
              {c.dial_code} - {c.code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <div
      className='my-font'
      style={{
        backgroundColor: "#fafafa",
        minHeight: "100vh",
        direction: "rtl",
      }}
    >
      <div>
        <Wave />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            position: "absolute",
            top: 5,
            left: 0,
            right: 0,
          }}
        >
          <Image
            preview={false}
            onClick={() => history.push("/")}
            src={logo}
            style={{ maxWidth: 100, resizeMode: "contain" }}
          />
          <Title level={4} align='center' style={{ color: banfsag }}>
            iVcard بطاقة الأعمال الذكية
          </Title>
        </div>
      </div>

      <Form
        {...formItemLayout}
        form={form}
        name='register'
        dir="rtl"
        onFinish={onFinish}
        style={{
          marginRight: 20,
          marginLeft: 20,
          marginTop: 20,
        }}
        initialValues={{
          prefixCellPhone: "+966",
        }}
        scrollToFirstError
        className='my-font'
      >
        <Form.Item
          name='firstName'
          style={{
            // display: "flex",
            // flexDirection: "column",
            // direction: "rtl",
            // textAlign: "right",
          }}
          label={<Text>الاسم الأول</Text>}
          rules={[{ required: true }]}
        >
          <Input dir='rtl' />
        </Form.Item>

        <Form.Item
          name='cellPhone'
          label='الهاتف'
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name='password'
          label='كلمة المرور'
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='agreement'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Should accept agreement")),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox className='my-font'>
            لقد قمت بالموافقة على{" "}
            <a href='https://ivcard.link' style={{ color: banfsag }}>
              الشروط والأحكمام
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button
            shape='round'
            style={{ backgroundColor: banfsag, color: light }}
            htmlType='submit'
          >
            تسجيل
          </Button>
        </Form.Item>
        <Text>لديك حساب ؟</Text>
        <Button
          type='link'
          style={{ color: banfsag }}
          className='my-font'
          onClick={() => history.push("/login")}
        >
          تسجيل الدخول
        </Button>
      </Form>
    </div>
  );
};

export default Register;
