
import {Route, BrowserRouter as Router} from 'react-router-dom'
import Home from "./pages/Home";
import Info from "./pages/Info";
import Profile from './pages/Profile';
import EditForm from './pages/EditForm';
import Register from './pages/Auth/Register';
import Login from './pages/Auth/Login';

// function toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     var reader = new FileReader();
//     reader.onloadend = function () {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open("GET", url);
//   xhr.responseType = "blob";
//   xhr.send();
// }


function App() {
 
  return (
   <Router>
     <Route path="/" exact component={Home} />
     <Route path="/info/:id" exact component={Info} />
     <Route path="/profile/" exact component={Profile} />
     <Route path="/edit/" exact component={EditForm} />
     <Route path="/register/" exact component={Register} />
     <Route path="/Login/" exact component={Login} />
   </Router>
  );
}

export default App;
