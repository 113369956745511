import * as React from "react"

function Wave(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" {...props}>
      <path
        fill="#212121"
        d="M0 96l80 37.3c80 37.7 240 111.7 400 144C640 309 800 299 960 298.7c160 .3 320 10.3 400 16l80 5.3V0H0z"
      />
    </svg>
  )
}

export default Wave
