import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import "./styles/global.css";
import App from "./App";
import { DataProvider, DataConsumer } from "./context";
import { ConfigProvider } from 'antd';

ReactDOM.render(
  // <React.StrictMode>
    <DataProvider>
      <DataConsumer>
        {(value) => (
          <ConfigProvider direction="rtl">
          <App />
          </ConfigProvider>
        )}
      </DataConsumer>
    </DataProvider>
  // </React.StrictMode>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
