import React from "react";
import Lottie from "react-lottie";
// import animationData from "./order-burger.json";



const LottieIcon = ({option, height, width, fileName, isStopped=false, loop=false, style={},  }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require(`./${fileName}`),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  let activeOption = defaultOptions;
  if(option){
    activeOption = option
  }
 
  return (
    
      <Lottie 
      isStopped={isStopped}
      options={activeOption} 
      style={style}
      height={height} width={width} />
      
  );
};

export default LottieIcon;
