import React, { useContext, useState } from "react";
import {
  Form,
  Input,
  Layout,
  Select,
  Row,
  Upload,
  Typography,
  Button,
  AutoComplete,
  Image,
  Divider,
  Skeleton,
  Avatar,
  message,
} from "antd";
import { UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import CountriesCode from "./Auth/CountriesCode.json";
import { DataContext } from "../context";
import apiUrl, { photoUrl } from "../API/config";
import { patchUser } from "../API/fetch";
import axios from "axios";
import { banfsag, light } from "../styles/colors";
import { useHistory } from "react-router-dom";
const { Header, Content } = Layout;

const { Text } = Typography;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const EditForm = () => {
  const [form] = Form.useForm();
  const { user, setUser } = useContext(DataContext);
  const history = useHistory();
  const onFinish = async (values) => {
    const data = {
      user: {
        ...values,
      },
    };

    try {
      const res = await patchUser(data, user.token);

      setUser(res.data.user);
      history.push(`/info/${res.data.user.slug}`);
    } catch (error) {
      if (
        error.response?.data?.message === "This phone is already registered"
      ) {
        alert("This phone is already registered, please Log in");
      } else {
        alert("Something went wrong, please try again");
      }
    }
  };

  const prefixSelectorCellPhone = (
    <Form.Item name='prefixCellPhone' noStyle>
      <Select
        style={{
          width: 130,
        }}
      >
        {CountriesCode.map((c) => {
          return (
            <Option key={c.code} value={c.dial_code}>
              {c.dial_code} - {c.code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  const prefixSelectorWorkPhone = (
    <Form.Item name='prefixWorkPhone' noStyle>
      <Select
        style={{
          width: 130,
        }}
      >
        {CountriesCode.map((c) => {
          return (
            <Option key={c.code} value={c.dial_code}>
              {c.dial_code} - {c.code}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          minHeight: "15vh",
          maxHeight: "15vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#212121",
          width: "100vw",
          position: "relative",
        }}
      >
        {" "}
        {user?.bg ? (
          <Image
            placeholder={
              <Skeleton.Avatar
                active={true}
                size={200}
                style={{
                  width: "100vw",
                  resizeMode: "contain",
                  position: "absolute",
                  minHeight: "15vh",
                  top: -102,
                }}
                shape={"square"}
              />
            }
            style={{
              width: "100vw",
              resizeMode: "center",
              overflow: "hidden",
              position: "relative",
            }}
            src={`${photoUrl}/${user.bg}`}
          />
        ) : null}
        <Avatar
          size={64}
          style={{
            position: "absolute",
            zIndex: 99,
            bottom: -32,
            borderWidth: "2px",
            borderColor: "#fff",
            borderStyle: "solid",
          }}
          icon={<Image src={`${photoUrl}/${user.photo}`} />}
        />
        <div
          onClick={() => history.goBack()}
          style={{ position: "absolute", top: 0, left: 10 }}
        >
          <ArrowLeftOutlined
            style={{
              color: banfsag,
              backgroundColor: "rgba(0,0,0,0.7)",
              paddingRight: 20,
              paddingLeft: 20,
              fontSize: 30,
            }}
          />
        </div>
      </Header>
      <Layout style={{ backgroundColor: "#fafafa", zIndex: 1 }}>
        <Content
          flex={1}
          justify='center'
          align='middle'
          style={{ paddingTop: "32px" }}
        >
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            onFinish={onFinish}
            style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}
            initialValues={{
              ...user,
            }}
            scrollToFirstError
          >
            <Form.Item name='mode' label='اختر طريقة العرض'>
              <Select>
                <Select.Option value='website'>الموقع الالكترني</Select.Option>
                <Select.Option value='vcf'>حفظ جهة الاتصال بشكل تلقائي</Select.Option>
                {user.snapchat ? (
                  <Select.Option value='SnapChat'>سناب شات</Select.Option>
                ) : null}
                {user.linkedIn ? (
                  <Select.Option value='Linkedin'>لينك دين</Select.Option>
                ) : null}
                {user.twitter ? (
                  <Select.Option value='Twitter'>تويتر</Select.Option>
                ) : null}
                {user.instagram ? (
                  <Select.Option value='Instagram'>انستقرام</Select.Option>
                ) : null}
              </Select>
            </Form.Item>
            <Form.Item
              name='firstName'
              label='الاسم الأول'
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='middleName' label='الاسم الثاني'>
              <Input />
            </Form.Item>
            <Form.Item name='lastName' label='الاسم الأخير'>
              <Input />
            </Form.Item>
            <Form.Item
              name='cellPhone'
              label='رقم الجوال'
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input
                addonBefore={prefixSelectorCellPhone}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item name='workPhone' label='رقم هاتف العمل'>
              <Input
                addonBefore={prefixSelectorWorkPhone}
                name='prefixWorkPhone'
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item
              name='email'
              label='البريد الالكتروني'
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name='organization' label='جهة العمل'>
              <Input />
            </Form.Item>
            <Form.Item name='title' label='المسمى الوظيفي'>
              <Input />
            </Form.Item>
            <Form.Item name='url' label='الموقع الالكتروني'>
              <AutoComplete
                options={websiteOptions}
                onChange={onWebsiteChange}
                placeholder='website'
              >
                <Input />
              </AutoComplete>
            </Form.Item>
            <Form.Item name='facebook' label='اسم المستخدم فيسبوك'>
              <Input />
            </Form.Item>
            <Form.Item name='linkedIn' label='اسم المستخدم لينكدن'>
              <Input
                addonAfter={"https://linkedin.com/in/"}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item name='twitter' label='اسم المستخدم تويتر'>
              <Input 
                addonAfter={"@"}
              />
            </Form.Item>
            <Form.Item name='snapchat' label='اسم المستخدم سناب شات'>
              <Input  addonAfter={"@"} />
            </Form.Item>
            <Form.Item name='instagram' label='اسم المستخدم انستقرام'>
              <Input   addonAfter={"@"}/>
            </Form.Item>
            <Row style={{ marginBottom: 20 }}>
              <Text>الصورة الشخصية</Text>
              <Divider type='vertical' />
              <Upload
                accept='image/png,image/jpeg,image/jpg'
                customRequest={async (e) => {
                  // check if the size more than 2mb;
                  if (e.file.size > 2000000) {
                    message.error("الحجم الأقصى للصورة 2 ميجا");
                    return;
                  }
                  const image = new FormData();
                  image.append("picture", e.file);
                  axios({
                    method: "POST",
                    url: `${apiUrl}/upload-photo/${user._id}`,
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                      "Content-Type": "multipart/form-data",
                    },
                    data: image,
                    onUploadProgress: e.onProgress,
                  })
                    .then((res) => {
                      setUser(res.data.user);
                      e.onSuccess();
                    })
                    .catch((err) => {
                      e.onError();
                    });
                }}
                listType='picture'
                maxCount={1}
                name='photo'
                
              >
                <Button icon={<UploadOutlined />}>اضغط لرفع صورة</Button>
              </Upload>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Text>صورة الخلفية</Text>
              <Divider type='vertical' />

              <Upload
                accept='image/png,image/jpeg,image/jpg'
                customRequest={async (e) => {
                  // check if the size more than 2mb;
                  if (e.file.size > 2000000) {
                    message.error("الحجم الأقصى للصورة 2 ميجا");
                    return;
                  }
                  const image = new FormData();
                  image.append("picture", e.file);
                  axios({
                    method: "POST",
                    url: `${apiUrl}/upload-bg/${user._id}`,
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                      "Content-Type": "multipart/form-data",
                    },
                    data: image,
                    onUploadProgress: e.onProgress,
                  })
                    .then((res) => {
                      setUser(res.data.user);
                      e.onSuccess();
                    })
                    .catch((err) => {
                      e.onError();
                    });
                }}
                maxCount={1}
                name='bg'
                listType='picture'
              >
                <Button icon={<UploadOutlined />}>اضغط لرفع صورة</Button>
              </Upload>
            </Row>

            <Form.Item {...tailFormItemLayout}>
              <Button
                shape='round'
                style={{
                  backgroundColor: banfsag,
                  color: light,
                  paddingLeft: 40,
                  paddingRight: 40,
                }}
                htmlType='submit'
              >
                حفظ
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EditForm;
