import React from "react";
import { Layout, Button, Image, Typography, } from "antd";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/ivcardLogo.png";
import { banfsag, light } from "../styles/colors";
import LottieIcon from "../Components/Lottie/LottieIcon";
const { Header, Content, Footer } = Layout;
const { Text, Title } = Typography;

const Home = () => {
  const history = useHistory();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          // minHeight: "15vh",
          // maxHeight: "15vh",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fafafa",
          paddingLeft: 0,
          paddingRight: 10,
          // width: "100vw",
          // position: "relative",
        }}
      >
        <div>
          <Button
            shape="round"
            onClick={() => history.push("/register")}
            style={{
              backgroundColor: banfsag,
              color: light,
              borderColor: light,
              marginRight: 10,
              marginLeft: 10,
            }}
            className="my-font"

          >
            تسجيل جديد
          </Button>
          <Button
            shape="round"
            className="my-font"
            onClick={() => history.push("/login")}
            style={{
              backgroundColor: light,
              color: banfsag,
              borderColor: banfsag,
            }}
          >
            تسجيل الدخول
          </Button>
        </div>

        <Image
          preview={false}
          src={logo}
          style={{ width: 80, height: 50, resizeMode: "contain" }}
        />
      </Header>
      <Layout style={{ backgroundColor: "#fafafa", zIndex: 1 }}>
        <Content
          flex={1}
          justify="center"
          align="middle"
          style={{ paddingTop: "32px", paddingRight: 10, paddingLeft: 10 }}
        >
          <LottieIcon fileName="tap-2.json" />

          <Title level={2} className="my-font">
           استبدل بطاقة الأعمال القديمة مع {" "}
            <span
              style={{ fontWeight: "700", fontSize: "2.5rem", color: banfsag }}
            >
              البطاقة الرقمية الجديدة
            </span>
          </Title>

          <Content style={{ paddingRight: "32px", paddingLeft: "32px" }}>
            <Text className="my-font" style={{ fontSize: 20 }}>
              مع تقنية ال NFC ، فقط قم بوضع البطاقة على جهاز الهاتف لتقوم بحفظ بيانات الاتصال الخاصة بك.
            </Text>
          </Content>
          <Content style={{width: '100vw',  display: 'flex', justifyContent: 'center'}}>

          {/* <Card
            style={{ width: 200, marginTop: '32px', borderRadius: 20 }}
            >
            <Title className="my-font" style={{color: banfsag}}>00</Title>
            <Text className="my-font" >/شهر</Text>
          </Card> */}
            </Content>
          <Content
            justify="center"
            align="middle"
            style={{
              backgroundColor: banfsag,
              paddingTop: "32px",
              paddingBottom: "32px",
              marginTop: "20px",
            }}
          >
            <Button
              shape="round"
              className="my-font"
              onClick={() => {
                // got to twitter account;
                window.open("https://twitter.com/nfcSmartCards", "_blank");
              }}
              style={{ color: banfsag, fontWeight: "700" }}
            >
             قم بطلب البطاقة الآن
            </Button>
          </Content>
        </Content>
        <Footer flex={1} justify="center" align="middle">
        <Text type="secondary">@copy </Text>
        <Text style={{color: banfsag}}> ivCard.link </Text>
        
      </Footer>
      </Layout>
    </Layout>
  );
};

export default Home;
